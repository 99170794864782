.toggle {
    position: relative;
    width: 30px;
    height: 15px;
    z-index: 99999;
    // background-color: black;
    border-radius: 15px;
    border: 1px solid #1B1C20;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    right: 50px;
    margin-top: .5px;
    @media(max-width: 991px){
        right: 2rem;
        transform: scale(0.8);
    }
    &.videoOver{
        filter: opacity(0.7) contrast(0.2) invert(1);
    }
    &.mobileVd{
        background-color: #eaeaea;
        .toggleHandle{
            background-color: #eaeaea;       
        }
    }
    svg{
        color: black;
        margin-left: 6px;
        margin-top: 7px;
        z-index: 99999;
        font-size: 12px;
        position: absolute;
    }
}

.toggleHandle {
    // position: absolute;
    width: 14.5px;
    height: 14.5px;
    margin-top: -2px;
    margin-left: -2px;
    // background-color: black;
    background-color: #EDECE8;
    border: 1px solid #1B1C20;
    border-radius: 50%;
    transition: transform 0.3s ease;
    box-shadow: 1px 3px 4px #1B1C20;
    transition-property: transform, background-color;
}

.toggle.checked {
    border-color: #fafafa;
    svg{
        left: 27px;
        color: #eaeaea;
    }
}

.toggle.checked .toggleHandle {
    transform: translateX(16px);
    border-color: #fafafa;
    background-color: #1B1C20;
    box-shadow: 1px 3px 4px #d1d1d1;

}