.container {
  position: relative;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  .bioContainer {
    max-width: 50%;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
  }
  .name {
    text-align: left;
    padding-left: 4rem;
    p {
      font-size: 22px;
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: flex-end;
      // margin: auto;
      text-align: right;
      max-width: 40%;
      margin-left: auto;
      margin-top: -10rem;
      margin-bottom: 5rem;
      margin-right: 2rem;
    }
    h1 {
      font-size: 39rem;
      margin: 0;
      padding: 0;
      letter-spacing: 15px;
      font-family: "TuskerGrotesk SemiBold";
      &:nth-child(2) {
        margin-top: -12rem;
      }
    }
  }
  .content {
    text-align: left;
    padding: 2rem;
    padding-left: 4rem;
    display: flex;
    flex-direction: column;
    position: fixed;
    justify-content: center;
    left: 9px;
    width: 40%;
    height: 100%;
    top: 0;
    .center {
      font-size: 24px;
      max-width: 50%;
      margin: 4rem 0;
      text-align: left;
    }
    .round {
      width: 10vw;
      height: 10vw;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 51px;
      margin-left: 20px;
      img {
        // height: 60%;
        max-width: 55%;
        margin-left: 5px;
        // // position: absolute;
        // padding-left: 0vw;
      }
      svg {
        width: 100%;
        animation: rotate 25s linear infinite;
        height: 100%;
        z-index: -1;
        margin-left: 15px !important;
        position: absolute;
      }
    }
    .top {
      display: flex;
      flex-direction: column;
      position: fixed;
      justify-content: center;
      left: 85px;
      width: 50%;
      height: 100%;
      top: 0;
      svg {
        margin-left: 3rem;
        g,
        path {
          fill: #0e0e0c ;
        }
      }
    }
  }
  h1 {
    font-family: "TuskerGrotesk";
    // font-size: 12rem;
    font-size: 11vw;
    letter-spacing: -4px;
  }
  .flex {
    display: flex;
  }
  .title {
    // padding-top: 12rem;
    padding-left: 0rem;
    margin-top: -50px;
    // margin-left: -16.9rem;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.contact {
  display: flex;
  align-items: center;
  width: 100%;
  // padding: 4rem 0;
  padding-top: 10rem;
  // overflow: hidden;
  margin-top: 5rem;
  height: 70vh;
  .leftText {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 0rem;
    max-width: 50%;
    margin-right: 2rem;
    h1 {
      // font-size: 13rem;
      font-size: 14vw;
      font-family: "TuskerGrotesk";
      text-transform: uppercase;
      text-align: left;
      line-height:14vw;
      letter-spacing: -2px;
      display: flex;
      flex-direction: column;
      span {
        margin-left: 5rem;
      }
    }
  }
  .rightside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35%;
    margin: 0 auto;
    .links {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-top: 3rem;
      margin: 0 auto;
      justify-content: center;
      a {
        color: #0e0e0c ;
        text-decoration: none;
        border-bottom: 1px solid #0e0e0c ;
        margin: 10px;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  form {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: 100%;
    margin: 0 auto;
    // margin-right: 5px;
    backdrop-filter: blur(30px);
    // margin-right: 5%;
    label {
      text-align: left;
      margin: 5px 0;
    }
    input,
    textarea {
      padding: 20px 10px;
      border-radius: 0px;
      background-color: transparent;
      color: #0e0e0c ;
      border: none;
      border-bottom: 1px solid #0e0e0c ;
      resize: none;
      &::placeholder {
        color: #0e0e0c ;
      }
      &:focus {
        outline: none;
      }
    }
    input {
      margin-bottom: 1rem;
    }
    button {
      margin: 2rem 0;
      background: none;
      border: none;
      cursor: pointer;
      color: #eaeaea;
      padding: 10px 35px;
      background-color: #0e0e0c ;
      border-radius: 30px;
      width: max-content;
      margin: 2rem auto;
      font-size: 18px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.sentModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .frame {
    padding: 3rem;
    border-radius: 10px;
    img {
      width: 150px;
      border-radius: 50%;
    }
    h1 {
      font-size: 24px;
      margin-top: 10px;
      font-family: "TuskerGrotesk";
    }
    p {
      font-size: 16px;
      margin-top: 10px;
    }
  }
}
@media (max-width: 1200px) {
  .container {
    .content {
   margin-left: -50px;
   width: 45%;
  }
  .bioContainer{
    max-width: 45%;

  }
  }
}
@media (max-width: 991px) {
  .container {
    justify-content: center;
    align-items: center;
    .content {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: -55px;
      margin-top: 3rem;
      h1 {
        font-size: 7rem;
        letter-spacing: 0;
      }
      // .top {
        position: relative;
        width: 70%;
        // left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .round {
          width: 100px;
          height: 100px;
          z-index: 1;
          margin-top: 30px;
        }
      // }
    }
    .bioContainer {
      max-width: none;
      width: 100%;
      margin: 0;
      margin-top: 1rem;
    }
  }
  .contact{
    flex-direction: column;
    margin-top: 0rem;
    height: auto;
    padding-top: 8rem;
    .leftText{
      max-width: 70%;
      h1{
        font-size: 10vw;
        line-height: 10vw;
        margin-top: -80px;
        span{
          margin-left: 2rem;
        }
      }
    }
    .rightside{
      width: 80%;
      label{
        font-size: 15px;
      }
      button{
        font-size: 15px;
      }
      .links{
       a{
        font-size: 14px;
       }
      }
    }
  }
}
@media (max-width: 575px) {
  .container {
    // padding-top: 5vh;
    .bioContainer{
      margin-top: -20px;
    }
    .content {
      h1 {
        font-size: 4.5rem;
      }
      .top {
        .round {
          width: 70px;
          height: 70px;
          margin-top: 15px;
          // margin-left: -5px;
        }
        img{
          margin-left: 11px;
        }
      }
    }
  }
  .contact{
    flex-direction: column;
    .rightside{
      width: 90%;
      margin-top: 20px;
    }
    .leftText{
      width: 100%;
     h1{
      text-align: left;
      flex-direction: row;
      font-size: 11vw;
      span{
        margin: 0 10px;
      }
     }
    }
  }
}

body:global(.dark-theme){
  .container{
    .round{
      svg{
        g,path{
          fill: #FF440A;
        }
      }
    }
  }
  .contact{
    form{
      input,iframe,textarea{
        color: #eaeaea;
        border-bottom-color: rgba(255, 255, 255, 0.413);
        &::placeholder{
          color: #eaeaea;
        }
      }
    }
   .rightside{
    button{
      background-color: #FF440A;
    }
    .links{
      a{
        color: #eaeaea;
        border-color: #eaeaea;
      }
    }
   }
  }
}