@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    display: flex;
    justify-content: center;
    padding: 1em 5.5em;
    margin-bottom: 2em;
    margin-top: 3vh;
    @media(max-width:768px){
        width: 100%;
        padding: 0;
        .galleryItem{
            height: 50vh;
        }
    }
    video{
        object-fit: fill;
        object-position: center;
        border-radius: 7px;
        // border-radius: 10px;
    }
}

.indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    background-color: #eaeaea;
    border-radius: 100%;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.gallery {
    display: flex;
    justify-content: space-around;
    width: 100%;
    // overflow: hidden;
}

.galleryItem {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56vh;
    margin: 0 5px;
    background-color: #0e0e0c ;
    // overflow: hidden;
    border-radius: 7px;
    position: relative;
    transition: flex 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    cursor: pointer;
    &:hover{
      p{
        opacity: 1;
      }
    }
    img {
        width: 550px;
        height: 100%;
        object-fit: contain;
        transform: scale(3);
    }
    p{
        position: absolute;
        bottom: -75px;
        color: black;
        text-align: left;
        width: 100%;
        margin-left: 0px;
        font-family: "TuskerGrotesk";
        font-size: 50px;
        text-transform: uppercase;
    }
}
body:global(.dark-theme){
    .galleryItem{
        p{
            color: #EDECE8;
        }
    }
}