.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0px;
  margin: 0 auto;
  width: 100%;
  position: fixed;
  top: 0;
  margin: 0 auto;
  padding-top: 45px;
  z-index: 9999;
  .slogan{
   max-width: none;
   width: 200px;
  }
  &.videoOver{
    svg,path{
      filter: opacity(0.8) contrast(0.2) invert(1);
    }.sublinks{
      p,.tagline,a{
      filter: opacity(0.8) contrast(0.2) invert(1);
      }
    }
  }
  &.mobileVd{
    svg{
      g,path{
        fill: #eaeaea;
      }
    }
    a{
      color: #eaeaea;
    }
  }
  .sublinks{
    display: flex;
    align-items: flex-start;
    position: relative;
    p{
      font-size: 1.1rem;
      line-height: 1.68rem;
      font-weight: 500;
      font-family: 'Montreal Medium';
      position: relative;
      span{
        font-family: 'Aminute';
      }
      .dot{
        color: #FF4508;
        font-size: 2.5rem;
         position: absolute;
         bottom: -0.3rem;
         margin-left: 5px;
      }
    }
    .tagline{
      text-align: left;
    }
    p{
      span{
        color: #FF4508;
      }
    }
    .columnlinks{
      text-align: left;
      margin-right: 9rem;
      display: flex;
      flex-direction: column;
    }
    a{
      // font-size: 16px;
      // margin: 5px 0px ;
      font-family: 'Montreal Medium';
      font-size: 1.1rem;
      line-height: 1.68rem;
      &:first-child{
        margin-bottom: 0rem;
      }
    }
  }
  .inner{
    width: 95%;
    padding: 0 30px;
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    &.home{
      width: 79%;
    }
  }
  &.bgNav {
    background-color: #edece8;
    transition: 0.4s ease-in-out;
  }
  .hamburger {
    font-size: 30px;
    cursor: pointer;
  }
  .links {
    display: flex;
    align-items: center;
    a {
      margin: 0 10px;
    }
  }

  a {
    font-weight: 500;
    font-size: 22px;
    cursor: pointer;
    &:hover {
      color: #ff4508;
    }
  }
  svg {
    max-width: 60px;
    cursor: pointer;
    &:hover {
      g,
      path {
        fill: #ff4508;
      }
    }
    g,
    path {
      fill: #282828;
    }
  }
}
.modalNavigation {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99999999;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  visibility: hidden;
  .logo {
    width: 60px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 9999;
    &:hover {
      g,
      path {
        fill: #ff4508;
      }
    }
    g,
    path {
      fill: #282828;
    }
  }
  &.showNavigation {
    visibility: visible;
    transition: all 0.5s ease;
  }
  .close {
    position: fixed;
    top: 30px;
    right: 25px;
    font-size: 40px;
    cursor: pointer;
    z-index: 9999999999999;
    color: #0e0e0c ;
  }
  .left,
  .right {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
    transition: all 0.5s ease;
    a {
      color: #eaeaea;
      font-size: 45px;
      font-weight: bolder;
      padding: 15px 10px;
      text-align: left;
      width: 50%;
      &.active {
        color: #0e0e0c ;
      }
    }
  }
  .left {
    background: #ff4508;
    transform: translateY(100%);
    a {
      &:hover {
        color: #0e0e0c ;
      }
    }
    &.fromBottom {
      transform: translateY(0%);
      transition: all 0.5s ease;
    }
  }
  .right {
    background-color: #EDECE8;
    transform: translateY(-100%);
    a {
      color: #0e0e0c ;
      &:hover {
        color: #ff4508;
      }
    }
    &.fromTop {
      transform: translateY(0%);
      transition: all 0.5s ease;
    }
  }
}

@media(max-width:991px){
    .container{
        padding-top: 10px;
        padding-bottom: 10px;
        .slogan{
          display: none;
        }
        .inner{
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
       .sublinks{
        padding-right: 0rem;
        .columnlinks{
          margin-right: 0rem;
          flex-direction: row;
          margin-left: 20px;
          margin-top: 20px;
        }
        a{
          font-size: 14px;
          margin: 0 10px;
        }
        .tagline{
          display: none;
        }
        p{
          display: none;
        }
       }
        .inner{
          .hamburger{
            font-size: 25px;
        }
        }
        svg{
            max-width: 35px;
        }
    }
    .modalNavigation{
      .close{
          font-size: 22px;
          top: 14px;
          right: 28px;
      }
      .logo{
        max-width: 35px;
        top: 10px;
      }
      .left, .right{
          a{
              font-size: 20px;
              padding: 5px 5px;
          }
      }
  }
}
@media(max-width:570px){
    .container{
        padding-top: 10px;
        padding-bottom: 10px;
       .inner{
        .hamburger{
          font-size: 22px;
      }
       }
        svg{
            max-width: 30px;
        }
    }
    .modalNavigation{
        .logo{
            max-width: 30px;
            top: 10px;
        }
        .left, .right{
            a{
                font-size: 20px;
                padding: 5px 5px;
            }
        }
    }
}
body:global(.dark-theme) {
 .container{
  svg{
    g,path{
      fill: #edece8;
    }
  }
 }
}
body:global(.dark-theme){
  .container{
    &.bgNav{
      background-color: #0e0e0c;
    }
  }
  .modalNavigation{
    .right{
      background-color: #0e0e0c;
      a{
        color: #eaeaea;
        &:hover{
          color: #ff4508;
        }
      }
    }
  }
}