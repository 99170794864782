.App {
  text-align: center;
  min-height: 100vh;
  background-color: #EDECE8;
  overflow: hidden;
  position: relative;
}
.dark-theme .App{
  background-color: #0e0e0c ;
}
.dark-theme *{
  color: #EDECE8;
}
.slider-work .slick-slide{
  width: max-content !important;
  margin:  0 40px;
}
.slider-work{
  position: absolute;
  width: 100%;
  max-height: 500px !important;
  overflow:  visible !important; 
  bottom: 2rem;
}
*{
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: 'TuskerGrotesk';
  src: url('./font/TuskerGrotesk.otf') format('opentype');
}
@font-face {
  font-family: 'TuskerGrotesk Bold';
  src: url('./font/TuskerGrotesk-1700Bold.ttf') format('opentype');
}
@font-face {
  font-family: 'TuskerGrotesk Medium';
  src: url('./font/TuskerGrotesk-1500Medium.otf') format('opentype');
}
@font-face {
  font-family: 'TuskerGrotesk SemiBold';
  src: url('./font/TuskerGrotesk-1500Medium.otf') format('opentype');
}
@font-face {
  font-family: 'Montreal Medium';
  src: url('./font/Montreal\ Medium.ttf') format('opentype');
}
@font-face {
  font-family: 'Aminute';
  src: url('./font/Aminute.otf') format('opentype');
}
@font-face {
  font-family: 'Montreal Regular';
  src: url('./font/Montreal-Regular.ttf') format('opentype');
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a{
  color: black;
  text-decoration: none;
  font-size: 20px;
}
.cls-2{
  fill: #ff4508 !important;
}