.ProjectsList{
    padding: 1rem 0;
    text-align: center;
    padding-top: 8rem;
    h1{
        font-size: 5rem;
        max-width: 80%;
       margin: 2rem auto;
       margin-bottom: 2rem;
       text-align: left;
         text-transform: uppercase;
        font-family: "TuskerGrotesk";
    }
    .list {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 1rem;
        justify-content: center;
        align-items: center;
        padding: 0 2rem;
        max-width: 80%;
        margin: 0 auto;
        @media(max-width:991px){
            grid-template-columns: repeat(1,1fr);
          }
        .item{
            position: relative;
            z-index: 1;
            cursor: pointer;
            transition: .5s ease-in-out;
            border-radius: 15px;
            width: 100%;
            overflow: hidden;
            &::after{
                position: absolute;
                top: 0;
                width: 100%;
                height: 99%;
                content: '';
                z-index: 0;
                visibility: hidden;
                left: 0;
                border-radius: 15px;
                background-color: #ff4608b2;
            }
            &:hover{
                &::after{
                    visibility: visible;
                }
            }
        }
        .title{
            width: 100%;
            height: 100%;
            text-align: center;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #0e0e0c ;
        }
        h2{
            font-size: 2rem;
            text-align: center;
            text-transform: uppercase;
            color: #eaeaea;
            z-index: 2;
            font-family: "TuskerGrotesk";
            position: absolute;
            bottom: 15px;
            left: 15px;
        }
        img{
            width: 100%;
            object-fit: cover;
            aspect-ratio: 16/9;
            border-radius: 15px;

        }
      }

}
@media(max-width:575px){
    .ProjectsList{
        h1{
            font-size: 3.5rem;
            max-width: 90%;
            margin-bottom: 2rem;
        }
        .list{
            max-width: 90%;
        }
    }
}