@font-face {
  font-family: "TuskerGrotesk";
  src: url("../font/TuskerGrotesk.otf") format("opentype");
}
.container {
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 2rem;
  h1 {
    font-size: 7rem;
    text-align: center;
    text-transform: uppercase;
    font-family: "TuskerGrotesk", sans-serif;
    margin: 0;
    // margin-top: 20px;
    letter-spacing: 5px;
    padding-top: 12rem;
    @media(max-width:991px){
     padding-top: 9rem;
    }
  }
  p {
    font-size: 18px;
    max-width: 50%;
    line-height: 26px;
    text-align: center;
    margin: 0 auto;
    @media(max-width:991px){
      max-width: 65%;
    }
  }
  .video {
    max-width: 91%;
    margin-top: 3rem;
    margin-bottom: 0;
    border-radius: 25px;
  }
  .gallery {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 8px;
    gap:10px;
    img {
      width: 50%;
      object-fit: cover;
      object-position: center;
      aspect-ratio: 16/9;
      border-radius: 25px;
    }
  }
  .mainImage {
    width: 90.6%;
    margin: 0 auto;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
      height: 700px;
      border-radius: 25px;
    }
  }
}

@media(max-width:575px){
.container{
  h1{
    font-size: 4.5rem;
    letter-spacing: 0;
  }
  p{
    max-width: 100%;
    font-size: 14px;
    line-height: 20px;
  }
  .mainImage{
    img{
      height: 300px;
    }
  }
}
}
