  
  .slider {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    >.items:hover{
        animation-play-state: paused;
     }
     >.items{
        display: flex;
        padding-left: 5rem;
        align-items: flex-end;
        z-index: 999999;
        margin-bottom: -2.9rem;
     }
     >.items > .item{
        min-width: max-content;
        padding: 0 2.5rem;
        cursor: pointer;
        // animation: slide-to-left 5s linear infinite;
     }
     .vditem{
        &:hover{
            div{
              color: #FF4508;
            }
          }
        }
        .vditem:not(:hover) {
          color: rgba(255, 255, 255, 0.266);
        }
        .vditem,.nonvditem{
          margin: 0 20px;
        }
  }
  .video{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  
 @keyframes slide-to-left {
        100% { transform: translateX(-100%) }
    }
  
    .main{
     overflow: hidden !important;
     height: 100vh;
    }
    

    body:global(.dark-theme) {
      .loader{
        background-color:#0e0e0c;
        color: #EDECE8;
      }
     .slider{
      span{
        color: #eaeaea;
      }
      .vditem{
        &:hover{
            div{
              color: #FF4508 !important;
              span{
              color: #FF4508 !important;

              }
            }
          }
        }
      .vditem:not(:hover) {
        color: rgba(255, 255, 255, 0.266) !important;
        opacity: 0.4;
      }
     }
    }
    .loader{
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      background-color: #EDECE8;
      color: #000000;
      z-index: 999999999999999 !important;
      @media(max-width:768px){
        padding-bottom: 20px;
      }
      h1{
        font-family: 'TuskerGrotesk';
        font-size: 15rem;
        margin: 0;
        padding: 0;
        margin-left: 2rem;
        margin-bottom: -2rem;
        @media(max-width:768px){
          font-size: 6rem;
        }
      }
      p{
        font-size: 2.5rem;
        margin-right: 4rem;
        margin-bottom: 2rem;
        font-family: 'Montreal Medium';
        @media(max-width:768px){
          font-size: 1rem;
          margin-bottom: 0;
        }
        span{
          font-family: 'Aminute';
        }
        .dot{
          color: #FF4508;
          font-size: 5rem;
           position: absolute;
           bottom: 0.5rem;
           margin-left: 10px;
           @media(max-width:768px){
            font-size: 2rem;
            bottom: 0.5rem;
          }
        }
      }
    }

    .shownLoader{
      visibility: visible;
      z-index: 99999999;
    }
    .hiddenLoader {
      transition: 2s ease-in-out;
      transform: translateY(-100%) ;
    }
    
    