.main{
    padding: 4rem 0;
    padding-left: 4rem;
    width: 100%;
    padding-top: 0.4rem;
    font-family: 'Montreal Medium';

    .bio__content{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .bioText{
            font-family: 'Montreal Regular';
            line-height: 30px;
            font-size: 24px;
        }
        .circle{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 1px solid #0e0e0c ;
            font-size: 16px;
            color: #0e0e0c ;
            width: 27px;
            height: 27px;
            border-radius: 50%;
            p{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: 3px;

            }
        }
        .right{
            text-align: left;
            font-size: 22px;
            // margin-left: 15%;
            width: 90%;
        }
        .left{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            h3{
                margin-left: 10px;
                text-transform: uppercase;
            }
        }
    }
    .exp__content{
        margin-top: 8rem;
        &.experienceItem{
            .item{
                position: relative;
                z-index: 1;
                padding-left: 0;
                transition: .5s ease-in-out;
                .flex{
                    display: flex;
                    align-items: center;
                    span{
                        margin-left: 20px;
                    }
                }
                span{
                    font-family: 'Aminute';
                }
                h4{
                    font-family: 'Montreal Medium';
                    &:first-child{
                        padding-left: 20px;
                    }
                    &:last-child{
                        padding-right: 20px;
                    }
                }
                &::after{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 0%;
                    transition: .5s ease-in-out;
                    content: '';
                    z-index: -1;
                    background-color: #FF4508;
                }
                &:hover{
                    color: #eaeaea;
                  &::after{
                    height: 100%;
                  }
                }
            }
        }
        .left{
            padding-top: 1.5rem;
        }
        .item{
            text-align: left;
            padding: 25px 0;
            border-bottom: 2px solid rgb(0, 0, 0);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 20px;
            position: relative;
        }
    }
    .skills__content{
    .item{
        position: relative;
        z-index: 1;
        &:hover{
            h4{
                &:first-child{
                    color: #eaeaea;
            transition: .5s ease-in-out;
                }
            }
        }
        h4{
            &:nth-child(2){
                font-size: 15px;
                margin-right: 15px;
                color: #000000;
            }
        }
        .after{
            position: absolute;
            background-color: #FF4508;
            left: 0;
            z-index: -1;
            height: 100%;
            transition: .5s ease-in-out;
        }
    }
    }
}
@media(max-width:1440px){
    .main{
        margin-right: 2rem;
        .bio__content{
            margin-top: 1rem;
            .bioText{
                font-size: 17px;
            }
           p{
            font-size: 15px;
           }
           h3{
            font-size: 16px;
           }
           h4,span{
            font-size: 13px;
           }
           .right{
            .item{
                padding: 15px 0;
                padding-left: 20px;
                &::after{
                    margin-left: -20px;
                }
            }
           }
        }
    }
}
@media(max-width:991px){
    .main{
        margin-right: 0;
        .bio__content{
            max-width: 90%;
            margin: 0 auto;
        }
    }
}
@media(max-width:575px){
    .main{
        .bio__content{
            margin-top: 1rem;
           p{
            font-size: 14px;
           }
           h3{
            font-size: 15px;
           }
           h4{
            font-size: 12px;
           }
           .right{
            .item{
                padding: 15px 0;
                padding-left: 20px;
            }
           }
        }
        .exp__content{
            .item{
                &::after{
                    margin-left: -20px;
                }
               h4{
                padding-left: 0 !important;
               }
            }
        }
    }
}
body:global(.dark-theme){
    .main{
        .bio__content{
            .circle{
                border-color: #EDECE8;
                color: #EDECE8;
            }
            .right{
                h4{
                    color: #eaeaea;
                }
            }
        }
        .exp__content{
            .item{
                border-color:rgb(175, 175, 175);
            }
        }
    }
}